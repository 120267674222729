import React, { Fragment } from 'react';

import extensionBrowserImageIcon from 'assets/images/extension-browser-image.png';
import boltTrioBlueIcon from 'assets/svg/bolt-trio-blue.svg';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { A, Button, Colorway, DefaultContainer, Div, H2, H4, Icon, P } from '@driftt/tide-core';

import { UserContext } from 'context/UserContext';
import { CHAT_APP_URL, popUp } from 'utils';
import { segmentPage, segmentTrack } from 'utils/metrics';

import './styles.css';

const onboardingStatesList = ['selection', 'notifications', 'permissions', 'declined'];

const QuickSetupColorway = () => (
  <Colorway type="3">
    <Icon name="cw-video" />
  </Colorway>
);

const GuidedSetupColorway = () => (
  <Colorway type="2">
    <Icon name="cw-be-prepared" />
  </Colorway>
);

const CardStepLayout = ({ selectQuickSetup, setOnboardingSelection }) => {
  const clickQuickOption = () => {
    segmentTrack('CTA Clicked', {
      product: 'video',
      location: 'Center Left',
      color: 'White',
      text: 'Quick Setup',
      CTA: 'Quick Setup',
      category: 'Fast Lane Onboarding Selection',
      type: 'Card',
    });

    // add cookie, expires 1yr out
    document.cookie =
      'onboarding_type_selection=quick; expires=' +
      new Date(new Date().setFullYear(new Date().getFullYear() + 1)) +
      '; path=/';

    setOnboardingSelection('quick');
    selectQuickSetup();
  };

  const clickGuidedOption = () => {
    segmentTrack('CTA Clicked', {
      product: 'video',
      location: 'Center Right',
      color: 'White',
      text: 'Guided Setup',
      CTA: 'Guided Setup',
      category: 'Fast Lane Onboarding Selection',
      type: 'Card',
    });

    // add cookie, expires 1yr out
    document.cookie =
      'onboarding_type_selection=guided; expires=' +
      new Date(new Date().setFullYear(new Date().getFullYear() + 1)) +
      '; path=/';

    setOnboardingSelection('guided');
    selectQuickSetup();
  };

  return (
    <Fragment>
      <Div className="video-onboarding-header">
        <img className="video-onboarding-bolts" alt="boltTrioBlueIcon" src={boltTrioBlueIcon} />
        <H2>You&rsquo;re ready to record your first video!</H2>
        <P>How much should we help you set up the rest of your account?</P>
      </Div>
      <Div className="video-onboarding-colorway-row">
        <Div className="video-onboarding-card" onClick={clickQuickOption}>
          <QuickSetupColorway />
          <H4>I&rsquo;ll explore on my own</H4>
          <P>Perfect for people who have used a recording tool before.</P>
        </Div>
        <Div className="video-onboarding-card" onClick={clickGuidedOption}>
          <GuidedSetupColorway />
          <H4>Guided tutorial</H4>
          <P>Recommended for those new to using a Chrome extension or video tool.</P>
        </Div>
      </Div>
    </Fragment>
  );
};

CardStepLayout.propTypes = {
  selectQuickSetup: PropTypes.func,
  selectGuidedSetup: PropTypes.func,
  setOnboardingSelection: PropTypes.func,
};

const NotificationsLayout = ({
  skipStep,
  onboardingSelection,
  handleActivateBrowserNotifications,
}) => (
  <div className="notifications-container">
    <Colorway>
      <Icon name="cw-video" />
    </Colorway>
    {onboardingSelection === 'quick' ? (
      <H2>Sweet! Do you want us to notify you of new views & conversations?</H2>
    ) : (
      <Fragment>
        <H2>
          Sweet! We&rsquo;ll help you out along the way.
          <br />
          First, do you want us to notify you of new views & conversations?
        </H2>
        <P>Don&rsquo;t worry, you can customize these settings later!</P>
      </Fragment>
    )}
    <div className="button-container">
      <Button onClick={handleActivateBrowserNotifications}>Allow notifications</Button>
      <Button
        type="tertiary"
        onClick={() => {
          segmentTrack('CTA Clicked', {
            product: 'video',
            location: 'Center',
            color: 'Grey',
            text: 'Not right now',
            CTA: 'Skip Notifications',
            category: 'Notifications selection',
            type: 'Text',
          });
          skipStep();
        }}>
        Not now
      </Button>
    </div>
  </div>
);

NotificationsLayout.propTypes = {
  skipStep: PropTypes.func,
  onboardingSelection: PropTypes.string,
  handleActivateBrowserNotifications: PropTypes.func,
};

const PermissionsLayout = ({ skipStep, onboardingSelection }) => (
  <Fragment>
    {onboardingSelection === 'quick' ? (
      <H2>
        One last thing &mdash; we need your permission to record with your camera and microphone.
      </H2>
    ) : (
      <Fragment>
        <H2>
          One last thing &mdash; we need your permission to record with your camera and microphone.
        </H2>
        <P>Don&rsquo;t worry, we don&rsquo;t record or store anything without your permission.</P>
      </Fragment>
    )}
    <div className="button-container">
      <Button
        className="video-onboarding-allow-permissions-button"
        onClick={() => {
          segmentTrack('CTA Clicked', {
            product: 'video',
            location: 'Center',
            color: 'Blue',
            text: 'Allow permissions',
            CTA: 'Allow permissions',
            category: 'Permissions selection',
            type: 'Button',
          });
        }}>
        Allow permissions
      </Button>
      <Button
        type="tertiary"
        onClick={() => {
          segmentTrack('CTA Clicked', {
            product: 'video',
            location: 'Center',
            color: 'Grey',
            text: 'Not right now',
            CTA: 'Skip Permissions',
            category: 'Permissions selection',
            type: 'Text',
          });
          skipStep();
        }}>
        Not now
      </Button>
    </div>
  </Fragment>
);

PermissionsLayout.propTypes = {
  skipStep: PropTypes.func,
  onboardingSelection: PropTypes.string,
};

const RecordingLayout = (ideaGeneratorPathname) => (
  <Fragment>
    <Div className="video-onboarding-extension-image-container">
      <img
        className="video-onboarding-extension-image"
        alt="extensionBrowserImageIcon"
        src={extensionBrowserImageIcon}
      />
      <Div className="video-onboarding-extension-copy">Drift Video</Div>
    </Div>
    <H2>All systems go! Open the Drift Video Chrome extension to begin recording.</H2>
    <A
      className="video-onboarding-idea-generator-cta"
      onClick={() => {
        segmentTrack('CTA Clicked', {
          product: 'video',
          location: 'Center',
          color: 'Grey',
          text: 'Need a video idea?',
          CTA: 'Idea generator',
          category: 'Idea generator',
          type: 'Text',
        });
      }}
      href={ideaGeneratorPathname}>
      Need a video idea?
    </A>
  </Fragment>
);

const PermissionsErrorLayout = () => (
  <Fragment>
    <Div className="video-onboarding-extension-image-container">
      <img
        className="video-onboarding-extension-image"
        alt="extensionBrowserImageIcon"
        src={extensionBrowserImageIcon}
      />
      <Div className="video-onboarding-extension-copy">Drift Video</Div>
    </Div>
    <H2>
      No worries, you can record just your screen for now. Open the Drift Video Chrome extension to
      begin!
    </H2>
    <P className="video-onboarding-error-subcopy">
      You can always give permissions in the extension later.
    </P>
  </Fragment>
);

export default class OnboardingApp extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      curState: onboardingStatesList[0],
      onboardingSelection: null,
    };
  }

  async componentDidMount() {
    segmentPage('Fast Lane Onboarding Selection Step');
  }

  nextState = () => {
    const curIdx = onboardingStatesList.indexOf(this.state.curState);

    this.setState({ curState: onboardingStatesList[curIdx + 1] });
  };

  setOnboardingSelection = (val) => this.setState({ onboardingSelection: val });

  handleActivateBrowserNotifications(onClose) {
    popUp({
      path: `${CHAT_APP_URL}/settings2/notifications/video`,
      windowName: 'Drift Video - configure browser notifications',
      onClose,
    });
  }

  render() {
    const { curState, onboardingSelection } = this.state;
    const { createUrlPath } = this.context;

    return (
      <DefaultContainer className="video-onboarding-container">
        <Div className="video-onboarding-content">
          <Div className={cx('video-onboarding-components', { visible: curState === 'selection' })}>
            <CardStepLayout
              selectQuickSetup={this.nextState}
              selectGuidedSetup={this.nextState}
              setOnboardingSelection={this.setOnboardingSelection}
            />
          </Div>
          <Div
            className={cx('video-onboarding-components', {
              visible: curState === 'notifications',
            })}>
            <NotificationsLayout
              skipStep={this.nextState}
              onboardingSelection={onboardingSelection}
              handleActivateBrowserNotifications={() =>
                this.handleActivateBrowserNotifications(this.nextState)
              }
            />
          </Div>
          <Div
            className={cx('video-onboarding-components', { visible: curState === 'permissions' })}>
            <PermissionsLayout
              skipStep={this.nextState}
              onboardingSelection={onboardingSelection}
            />
          </Div>
          <Div className="video-onboarding-components video-onboarding-success-content">
            <RecordingLayout ideaGeneratorPathname={createUrlPath('/video/idea/')} />
          </Div>
          <Div
            className={cx('video-onboarding-components', 'video-onboarding-error-content', {
              visible: curState === 'declined',
            })}>
            <PermissionsErrorLayout />
          </Div>
        </Div>
      </DefaultContainer>
    );
  }
}
