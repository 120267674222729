export default function buildGradientStyle({ video, videos, sortKey, sortDirection }) {
  if (!videos || !videos.length) {
    return {};
  }

  let numerator = 0;
  let denominator = 1;

  const maxIndex = sortDirection === 'desc' ? 0 : videos.length - 1;

  switch (sortKey) {
    case 'view_count':
      numerator = video.viewCount;
      denominator = videos[maxIndex].viewCount;
      break;
    case 'num_conversations':
      numerator = video.numConversations;
      denominator = videos[maxIndex].numConversations;
      break;
    case 'average_percent_viewed':
      numerator = video.averagePercentViewed;
      denominator = videos[maxIndex].averagePercentViewed;
      break;
    default:
      break;
  }

  const percent = Math.ceil((numerator / (denominator || 1)) * 100);
  const background = `linear-gradient(to right, rgba(199, 225, 255, 0.4) ${percent}%, transparent ${percent}%)`;

  return { background };
}
