import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import * as qs from 'query-string';

import { Button, Div, Footer, Input, Select, Sidebar } from '@driftt/tide-core';

const FilterSidebar = ({
  title,
  isOpen,
  setOpen,
  createdByUsers,
  setCreatedByUsers,
  createdByOptions,
  filterMap,
}) => {
  let history = useHistory();

  // This is a temporary copy of the filterMap that will replace it when "apply filter" is called
  const [queryStringMap, setQueryStringMap] = useState({});

  const handleFilterSelectionChange = (options) => {
    setCreatedByUsers(options);
  };

  const onApplyFilter = () => {
    const search =
      '?' +
      qs.stringify({
        user_ids: createdByUsers ? createdByUsers.map((user) => user.value).join(',') : '',
        min_meetings_booked: queryStringMap.minMeetingsBooked,
        max_meetings_booked: queryStringMap.maxMeetingsBooked,
        min_video_views: queryStringMap.minVideoViews,
        max_video_views: queryStringMap.maxVideoViews,
      });
    history.push(search);
    setOpen(false);
  };

  useEffect(() => {
    setQueryStringMap({
      userIds: filterMap.user_ids ? filterMap.user_ids : '',
      minMeetingsBooked: filterMap.min_meetings_booked ? filterMap.min_meetings_booked : '',
      maxMeetingsBooked: filterMap.max_meetings_booked ? filterMap.max_meetings_booked : '',
      minVideoViews: filterMap.min_video_views ? filterMap.min_video_views : '',
      maxVideoViews: filterMap.max_video_views ? filterMap.max_video_views : '',
    });
  }, [filterMap]);

  const onClearAllFilters = () => {
    const search = '?' + qs.stringify({});
    history.push(search);
  };

  return (
    <Sidebar
      isOpen={isOpen}
      toggleWidget={false}
      hideOverlay={true}
      title={`Filter ${title}`}
      close={() => setOpen(false)}>
      <Div className="filter-types-container">
        <Div className="filter-header">Created by</Div>
        <Div className="filter-types">
          <Select
            multi={true}
            placeholder={'Add one or more teammates'}
            value={createdByUsers || ''}
            options={createdByOptions}
            onChange={handleFilterSelectionChange}
          />
        </Div>
      </Div>
      <Div className="filter-types-container">
        <Div className="filter-header">Views</Div>
        <Div className="filter-types">
          <Div className="filter-relation-container">
            <Div className="filter-operator">Greater than</Div>
            <Input
              placeholder="Add a number value"
              value={queryStringMap.minVideoViews || ''}
              onChange={({ target }) =>
                setQueryStringMap({
                  ...queryStringMap,
                  minVideoViews: target.value,
                })
              }
            />
          </Div>

          <Div className="filter-relation-container">
            <Div className="filter-operator">Less than</Div>
            <Input
              placeholder="Add a number value"
              value={queryStringMap.maxVideoViews || ''}
              onChange={({ target }) =>
                setQueryStringMap({
                  ...queryStringMap,
                  maxVideoViews: target.value,
                })
              }
            />
          </Div>
        </Div>
      </Div>
      <Div className="filter-types-container">
        <Div className="filter-header">Meetings booked</Div>
        <Div className="filter-types">
          <Div className="filter-relation-container">
            <Div className="filter-operator">Greater than</Div>
            <Input
              placeholder="Add a number value"
              value={queryStringMap.minMeetingsBooked || ''}
              onChange={({ target }) =>
                setQueryStringMap({
                  ...queryStringMap,
                  minMeetingsBooked: target.value,
                })
              }
            />
          </Div>

          <Div className="filter-relation-container">
            <Div className="filter-operator">Less than</Div>
            <Input
              placeholder="Add a number value"
              value={queryStringMap.maxMeetingsBooked || ''}
              onChange={({ target }) =>
                setQueryStringMap({
                  ...queryStringMap,
                  maxMeetingsBooked: target.value,
                })
              }
            />
          </Div>
        </Div>
      </Div>
      <Footer className="filter-builder--buttons">
        <Button
          className="filter-builder--apply"
          size="medium"
          type="primary"
          onClick={onApplyFilter}>
          Apply
        </Button>
        <Button
          className="filter-builder--apply"
          size="medium"
          type="tertiary"
          onClick={onClearAllFilters}>
          Clear all
        </Button>
      </Footer>
    </Sidebar>
  );
};

FilterSidebar.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  createdByUsers: PropTypes.array,
  setCreatedByUsers: PropTypes.func,
  createdByOptions: PropTypes.array,
  filterMap: PropTypes.object,
};

export default FilterSidebar;
