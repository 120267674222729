import React from 'react';

import { ProgressiveLoader } from '@driftt/tide-core';

function formatUser(user) {
  if (!user) {
    return null;
  }

  return [user.firstName, user.lastName].filter(Boolean).join(' ') || user.email;
}

function LoadingUser() {
  return (
    <ProgressiveLoader
      placeholderShape={<rect x="0" y="54" width="100%" height="45" />}
      numPlaceholders={1}
    />
  );
}

export default function getColumns({
  users,
  usersLoading,
  sortKey,
  sortDirection,
  handleTableHeaderClicked,
}) {
  return [
    {
      label: 'Video',
      width: '36%',
      sortable: true,
      sorting: sortKey === 'title',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('title'),
      getContent: ({ title, thumbnailUrl, thumbnailDefault }) => (
        <div className="videos-report__thumbnail-and-title">
          <div className="videos-report__thumbnail">
            <img src={thumbnailUrl || thumbnailDefault} alt="" />
          </div>
          <div className="videos-report__title">{title}</div>
        </div>
      ),
    },
    {
      label: 'Created by',
      width: '20%',
      sortable: true,
      sorting: sortKey === 'user',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('user'),
      getContent: (video) => {
        const user = users.find(({ id }) => id === video.user);
        return usersLoading ? <LoadingUser /> : formatUser(user);
      },
    },
    {
      label: 'Views',
      width: '11%',
      sortable: true,
      sorting: sortKey === 'view_count',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('view_count'),
      getContent: ({ viewCount }) => viewCount,
    },
    {
      label: 'Avg % viewed',
      width: '11%',
      sortable: true,
      sorting: sortKey === 'average_percent_viewed',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('average_percent_viewed'),
      getContent: ({ averagePercentViewed }) =>
        averagePercentViewed !== null ? averagePercentViewed + '%' : '-',
    },
    {
      label: 'Conversations',
      width: '11%',
      sortable: true,
      sorting: sortKey === 'num_conversations',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('num_conversations'),
      getContent: ({ numConversations }) => numConversations,
    },
    {
      label: 'Meetings booked',
      width: '11%',
      sortable: true,
      sorting: sortKey === 'num_meetings',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('num_meetings'),
      getContent: ({ numMeetings }) => numMeetings,
    },
  ];
}
