import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import * as qs from 'query-string';

import { UserContext } from 'context/UserContext';

import PerformanceReportPage from './PerformanceReportPage';
import VideoReports from './VideoReports';

import '@driftt/react-dates/initialize';

import './styles.css';
import '@driftt/react-dates/lib/css/_datepicker.css';

const ReportsApp = () => {
  const { userHasFeature, appLoading } = React.useContext(UserContext);
  const hasVideoDetailsReport = userHasFeature('TEAMMATE_VIDEO_DETAILS_REPORT');
  const hasPerformanceReport = userHasFeature('HAS_TEAM_VIDEO_REPORTS');

  useEffect(() => {
    document.title = 'Drift | Video reports';
  }, []);

  return appLoading ? (
    <></>
  ) : (
    <>
      <Route
        exact
        path="/video/reports/"
        render={({ location }) => {
          return hasVideoDetailsReport ? (
            <VideoReports filterMap={qs.parse(location.search)} />
          ) : (
            <Redirect to="/video/" />
          );
        }}
      ></Route>
      <Route
        exact
        path="/video/reports/performance/"
        render={({ location }) => {
          return hasPerformanceReport ? (
            <PerformanceReportPage filterMap={qs.parse(location.search)} />
          ) : (
            <Redirect to="/video/reports/" />
          );
        }}
      />
    </>
  );
};

export default ReportsApp;
