import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';
import * as qs from 'query-string';

import {
  Button,
  CardContainer,
  Chip,
  Div,
  H4,
  IconButton,
  Main,
  NavigationHeader,
  Paginator,
  Table,
} from '@driftt/tide-core';

import { fetchTeam, fetchUserVideosForReports, fetchVideosReport } from 'api';
import { UserContext } from 'context/UserContext';
import { createCSVDownload, generateShareUrl } from 'utils';
import {
  determineChipFilters,
  fetchAndReturnCurrentTeamUsers,
  parseDateToRange,
} from 'utils/reportsUtils';

import DatePicker from '../components';
import FilterSidebar from '../FilterSidebar';

import buildGradientStyle from './buildGradientStyle';
import getColumns from './getColumns';

import './styles.css';

const DEFAULT_SORT_KEY = 'view_count';

const VideoReports = ({ filterMap }) => {
  let history = useHistory();
  const { user } = useContext(UserContext);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(moment());
  const [sortKey, setSortKey] = useState(DEFAULT_SORT_KEY);
  const [sortDirection, setSortDirection] = useState('desc');
  const [usersLoading, setUsersLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [count, setCount] = useState(null);
  const [videosLoading, setVideosLoading] = useState(true);
  const [videos, setVideos] = useState([]);

  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);
  const [createdByOptions, setCreatedByOptions] = useState([]);
  const [createdByUsers, setCreatedByUsers] = useState('');
  const [filters, setFilters] = useState([]);

  const removeFilter = (key, value) => {
    let nextValue = undefined;

    if (key === 'user_ids') {
      nextValue =
        filterMap.user_ids
          .split(',')
          .filter((id) => id !== value.toString())
          .join(',') || undefined;
    }

    // Update the queryString
    const search = '?' + qs.stringify({ ...filterMap, [key]: nextValue });
    history.push(search);
  };

  useEffect(() => {
    setFilters(determineChipFilters(filterMap, createdByUsers));
  }, [filterMap, createdByUsers]);

  useEffect(() => {
    fetchAndReturnCurrentTeamUsers(filterMap.user_ids).then(({ options, users }) => {
      setCreatedByOptions(options);
      setCreatedByUsers(users);
    });
  }, [filterMap.user_ids]);

  useEffect(() => {
    const doFetch = async () => {
      setUsersLoading(true);

      try {
        const users = await fetchTeam();
        setUsers(users);
      } catch (e) {
        Error(e);
      } finally {
        setUsersLoading(false);
      }
    };

    doFetch();
  }, []);

  useEffect(() => {
    const doFetch = async () => {
      setVideosLoading(true);

      try {
        const response = await fetchUserVideosForReports({
          sortKey,
          sortDescending: sortDirection === 'desc',
          after: startDate && startDate.format('YYYY-MM-DD'),
          before: endDate && endDate.format('YYYY-MM-DD 23:59:59'),
          filterMap,
          pageSize,
          page: pageIndex + 1,
        });

        setVideos(response.results);
        setCount(response.count);
      } catch (e) {
        Error(e);
      } finally {
        setVideosLoading(false);
      }
    };

    doFetch();
  }, [sortKey, sortDirection, startDate, endDate, filterMap, pageSize, pageIndex]);

  const exportCsv = async () => {
    try {
      const data = await fetchVideosReport({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      });
      createCSVDownload(data, 'videos_report');
    } catch (e) {
      Error(e);
    }
  };

  const handleTableHeaderClicked = (columnSortKey) => {
    const newSortDirection =
      sortKey === columnSortKey ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'desc';
    setSortKey(columnSortKey);
    setSortDirection(newSortDirection);
  };

  const columns = getColumns({
    users,
    usersLoading,
    sortKey,
    sortDirection,
    handleTableHeaderClicked,
  });

  const data = videos.map((video) => ({
    key: 'video-' + video.id,
    style: buildGradientStyle({ video, videos, sortKey, sortDirection }),
    data: columns.map((column) => ({ content: column.getContent(video) })),
    rowClickEventData: {
      videoUrl: video.user === user.id ? generateShareUrl(video.shareUrl) : video.publicUrl,
    },
  }));

  return (
    <Div className="videos-report__wrapper">
      <Div className="video-report-header-components">
        <NavigationHeader title="Videos overview" />
        <Div className="videos-report__controls">
          <DatePicker
            handleEndDateChange={setEndDate}
            handleStartDateChange={setStartDate}
            startDate={startDate}
            endDate={endDate}
          />
          <IconButton icon="pb-filter" size="medium" onClick={() => setFilterSidebarOpen(true)} />
        </Div>
      </Div>
      <FilterSidebar
        title={'videos'}
        isOpen={filterSidebarOpen}
        setOpen={(value) => {
          setFilterSidebarOpen(value);
        }}
        createdByUsers={createdByUsers}
        setCreatedByUsers={(users) => setCreatedByUsers(users)}
        createdByOptions={createdByOptions}
        filterMap={filterMap}
      />
      <Main className="video-report-main-content">
        <CardContainer padding="large">
          <Div className="chart-header">
            <Div className="chart-header-primary-content">
              <H4 className="chart-header-title">Team videos</H4>
              <Div className="chart-header-components">
                <Div className="chart-header-date-display">
                  {parseDateToRange(startDate, endDate)}
                </Div>
                {Object.keys(filters).length > 0 && (
                  <Div className="chart-header-chip-container">
                    <Div className="stupid-separator-pipe-thing" />
                    <Div className="chart-header-filters">
                      {filters.map(({ key, value, label }, index) => (
                        <Chip
                          key={index}
                          label={label}
                          onRemoveClick={() => removeFilter(key, value)}
                        />
                      ))}
                      {Object.keys(filters).length > 0 && (
                        <Div onClick={() => history.push('?')} className="clear-all-filters">
                          Clear all
                        </Div>
                      )}
                    </Div>
                  </Div>
                )}
              </Div>
            </Div>
            <Button type="tertiary" className="report-export-btn" onClick={exportCsv}>
              Export CSV
            </Button>
          </Div>
          <Table
            className="videos-report__table"
            isWorking={videosLoading}
            columns={columns}
            data={data}
            onRowClick={({ videoUrl }) => window.open(videoUrl)}
          />
          {videos.length > 0 && (
            <Paginator
              position="static"
              index={pageIndex}
              size={pageSize}
              sizes={[10, 25, 50]}
              changeSize={(event) => {
                setPageIndex(0);
                setPageSize(event.value);
              }}
              totalPages={Math.ceil(count / pageSize)}
              goForward={() => setPageIndex((index) => index + 1)}
              goBackward={() => setPageIndex((index) => index - 1)}
            />
          )}
        </CardContainer>
      </Main>
    </Div>
  );
};

VideoReports.propTypes = {
  filterMap: PropTypes.object,
};

export default VideoReports;
