import React, { useEffect, useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { Div, IconButton, Main, NavigationHeader } from '@driftt/tide-core';

import { fetchTeam } from 'api';
import { segmentPage } from 'utils/metrics';
import { fetchAndReturnCurrentTeamUsers } from 'utils/reportsUtils';

import DatePicker from './components';
import FilterSidebar from './FilterSidebar';
import PerformanceReport from './PerformanceReport';

const TeamReports = ({ filterMap }) => {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(moment());
  const [users, setUsers] = useState([]);

  // Handle querystring filtering state
  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);
  const [createdByUsers, setCreatedByUsers] = useState('');
  const [createdByOptions, setCreatedByOptions] = useState([]);

  const title = 'Team performance overview';

  useEffect(() => {
    segmentPage('Team Video Reports Viewed');
  }, []);

  useEffect(() => {
    fetchAndReturnCurrentTeamUsers(filterMap.user_ids).then(({ options, users }) => {
      setCreatedByOptions(options);
      setCreatedByUsers(users);
    });
  }, [filterMap.user_ids]);

  useEffect(() => {
    if (users.length === 0) {
      fetchTeam()
        .then((response) => {
          if (response) {
            setUsers(response);
          }
        })
        .catch((err) => {
          Error(err);
        });
    }
  }, [users.length]);

  return (
    <Div className="video-report-wrapper">
      <Div className="video-report-header-components">
        <NavigationHeader title={title} />
        <Div className="video-report-controls">
          <DatePicker
            handleEndDateChange={setEndDate}
            handleStartDateChange={setStartDate}
            startDate={startDate}
            endDate={endDate}
          />
          {<IconButton icon="pb-filter" size="medium" onClick={() => setFilterSidebarOpen(true)} />}
        </Div>
      </Div>
      <FilterSidebar
        title={'teammates'}
        isOpen={filterSidebarOpen}
        setOpen={(value) => {
          setFilterSidebarOpen(value);
        }}
        createdByUsers={createdByUsers}
        setCreatedByUsers={(users) => setCreatedByUsers(users)}
        createdByOptions={createdByOptions}
        filterMap={filterMap}
      />
      <Main className="video-report-main-content">
        <PerformanceReport
          startDate={startDate}
          endDate={endDate}
          users={users}
          createdByUsers={createdByUsers}
          filterMap={filterMap}
        />
      </Main>
    </Div>
  );
};

TeamReports.propTypes = {
  filterMap: PropTypes.object,
};

export default TeamReports;
