import React, { useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { DriftDatePicker } from '@driftt/react-dates';
import { Div } from '@driftt/tide-core';

import '@driftt/react-dates/lib/css/_datepicker.css';
import './styles.css';

const DatePicker = ({ startDate, endDate, handleStartDateChange, handleEndDateChange }) => {
  const [focusedInput, setFocusedInput] = useState(null);

  const earliestDate = moment().startOf('day').subtract(6, 'months');

  const onDateChange = ({ startDate: start, endDate: end }) => {
    start && handleStartDateChange(start);
    end && handleEndDateChange(end);
  };

  const isOutsideRange = (day) => {
    return moment(day).isAfter(moment().endOf('day')) || moment(day).isBefore(earliestDate);
  };

  return (
    <Div className="date-range-picker">
      <DriftDatePicker
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDateChange}
        focusedInput={focusedInput}
        onFocusChange={(newFocus) => setFocusedInput(newFocus)}
        isOutsideRange={isOutsideRange}
        showQuickSelects={true}
        anchorDirection="left"
      />
    </Div>
  );
};

DatePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  handleStartDateChange: PropTypes.func,
  handleEndDateChange: PropTypes.func,
};

export default DatePicker;
