import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import * as qs from 'query-string';

import {
  Avatar,
  Button,
  CardContainer,
  Chip,
  Colorway,
  Div,
  H4,
  Icon,
  Table,
} from '@driftt/tide-core';

import { downloadTeamVideoReport, fetchVideoStatsByUser } from 'api';
import { createCSVDownload, lookupUser } from 'utils';
import { determineChipFilters, parseDateToRange } from 'utils/reportsUtils';

const PerformanceReport = ({ startDate, endDate, users, createdByUsers, filterMap }) => {
  let history = useHistory();

  const DEFAULT_SORT_KEY = 'videos_created';

  const [statsLoading, setStatsLoading] = useState(true);
  const [userStats, setUserStats] = useState([]);
  const [sortKey, setSortKey] = useState(DEFAULT_SORT_KEY);
  const [sortDirection, setSortDirection] = useState('desc');
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters(determineChipFilters(filterMap, createdByUsers));
  }, [filterMap, createdByUsers]);

  useEffect(() => {
    setStatsLoading(true);
    fetchVideoStatsByUser(
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      sortKey,
      sortDirection === 'asc',
      filterMap,
    )
      .then((data) => {
        setUserStats(data);
      })
      .catch((err) => {
        Error(err);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  }, [startDate, endDate, sortKey, sortDirection, filterMap]);

  const exportCsv = () => {
    downloadTeamVideoReport(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .then((data) => {
        createCSVDownload(data, 'team_reports');
      })
      .catch((err) => {
        Error(err);
      });
  };

  const handleTableHeaderClicked = (columnSortKey) => {
    const newSortDirection =
      sortKey === columnSortKey ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'desc';
    setSortKey(columnSortKey);
    setSortDirection(newSortDirection);
  };

  const buildGradientStyle = (userStat, allStats) => {
    if (statsLoading) {
      return { background: '#ffffff' };
    }

    let numerator = 0;
    let denominator = 1;
    const maxIndex = sortDirection === 'desc' ? 0 : allStats.length - 1;
    switch (sortKey) {
      case 'videos_created':
        numerator = userStat.videosCreated;
        denominator = allStats[maxIndex].videosCreated;
        break;
      case 'views':
        numerator = userStat.views;
        denominator = allStats[maxIndex].views;
        break;
      case 'average_percent_viewed':
        numerator = userStat.averagePercentViewed;
        denominator = allStats[maxIndex].averagePercentViewed;
        break;
      case 'num_conversations_started':
        numerator = userStat.numConversationsStarted;
        denominator = allStats[maxIndex].numConversationsStarted;
        break;
      case 'num_meetings':
        numerator = userStat.numMeetings;
        denominator = allStats[maxIndex].numMeetings;
        break;
      default:
        break;
    }

    const ratio = Math.ceil((numerator / (denominator || 1)) * 100);
    return {
      background: `linear-gradient(to right, rgba(199, 225, 255, 0.4) ${ratio}%, rgb(255, 255, 255) ${ratio}%)`,
    };
  };

  const removeFilter = (key, value) => {
    let nextValue = undefined;

    if (key === 'user_ids') {
      nextValue =
        filterMap.user_ids
          .split(',')
          .filter((id) => id !== value.toString())
          .join(',') || undefined;
    }

    // Update the queryString
    const search = '?' + qs.stringify({ ...filterMap, [key]: nextValue });
    history.push(search);
  };

  return (
    <CardContainer padding="large" className="performance-report">
      <Div className="chart-header">
        <Div className="chart-header-primary-content">
          <H4 className="chart-header-title">Who are your top performers?</H4>
          <Div className="chart-header-components">
            <Div className="chart-header-date-display">{parseDateToRange(startDate, endDate)}</Div>
            {Object.keys(filters).length > 0 && (
              <Div className="chart-header-chip-container">
                <Div className="stupid-separator-pipe-thing" />
                <Div className="chart-header-filters">
                  {filters.map(({ key, value, label }, index) => (
                    <Chip
                      key={index}
                      label={label}
                      onRemoveClick={() => removeFilter(key, value)}
                    />
                  ))}
                  {Object.keys(filters).length > 0 && (
                    <Div onClick={() => history.push('?')} className="clear-all-filters">
                      Clear all
                    </Div>
                  )}
                </Div>
              </Div>
            )}
          </Div>
        </Div>
        <Button type="tertiary" className="report-export-btn" onClick={exportCsv}>
          Export CSV
        </Button>
      </Div>
      <Table
        className="report-table"
        isWorking={statsLoading}
        columns={[
          {
            label: 'Drift teammate',
            width: '30%',
          },
          {
            label: 'Videos created',
            sortable: true,
            sorting: sortKey === 'videos_created',
            direction: sortDirection,
            onClick: () => handleTableHeaderClicked('videos_created'),
          },
          {
            label: 'Video views',
            sortable: true,
            sorting: sortKey === 'views',
            direction: sortDirection,
            onClick: () => handleTableHeaderClicked('views'),
          },
          {
            label: 'Average % viewed',
            sortable: true,
            sorting: sortKey === 'average_percent_viewed',
            direction: sortDirection,
            onClick: () => handleTableHeaderClicked('average_percent_viewed'),
          },
          {
            label: 'Conversations started',
            sortable: true,
            sorting: sortKey === 'num_conversations_started',
            direction: sortDirection,
            onClick: () => handleTableHeaderClicked('num_conversations_started'),
          },
          {
            label: 'Meetings booked',
            sortable: true,
            sorting: sortKey === 'num_meetings',
            direction: sortDirection,
            onClick: () => handleTableHeaderClicked('num_meetings'),
          },
        ]}
        data={[
          ...userStats.map((userStat) => ({
            style: buildGradientStyle(userStat, userStats),
            data: [
              {
                content: (
                  <Div className="name-avatar-container">
                    <Avatar user={lookupUser(users, userStat.userId)} />
                    {lookupUser(users, userStat.userId).name}
                  </Div>
                ),
              },
              { content: userStat.videosCreated },
              { content: userStat.views },
              { content: userStat.averagePercentViewed + '%' },
              { content: userStat.numConversationsStarted },
              { content: userStat.numMeetings },
            ],
          })),
        ]}
      />
      {Object.keys(filters).length > 0 && userStats.length === 0 && (
        <Div className="empty-report-container">
          <Colorway className="empty-report-colorway">
            <Icon name="cw-empty-search" />
          </Colorway>
          <Div className="empty-report-text">
            {`We couldn't find any teammates that have videos matching your filters`}
          </Div>
        </Div>
      )}
    </CardContainer>
  );
};

PerformanceReport.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  userStats: PropTypes.arrayOf(PropTypes.object),
  statsLoading: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  exportCsv: PropTypes.func,
  createdByUsers: PropTypes.arrayOf(PropTypes.object),
  filterMap: PropTypes.object,
};

export default PerformanceReport;
